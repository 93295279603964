import { OfficeViewAdditionalLines, OfficeViewMiddleHeader, OfficeViewMiddleNumbers, OfficeViewSearchResults, SearchableOptions } from 'dyl-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import contactsActions from 'actions/contacts';
import './index.scss';

const LIMIT = 15;

const OfficeViewPhoneMiddle = ({ onNumberClick, search, setSearch, isSearchResultsVisible, setIsSearchResultsVisible, page, setPage, cancelFunction }) => {
    const dispatch = useDispatch();
    const { isSearchingContacts, contacts_searched_count, contact_lookup } = useSelector((state) => state.contacts);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
        setIsSearchResultsVisible(value?.trim().length >= 2);
    }

    const searchFunction = async () => {
        try {
            setPage(1);
            await dispatch(contactsActions.contactSearch({ page: 1, search, limit: LIMIT }));
        } catch (e) {
            console.log(e)
        }
    }

    const onPersonSelect = async (id) => {
        try {
            await dispatch(contactsActions.viewLookup(id));
            setPage(1);
            setSearch('');
            setIsSearchResultsVisible(false);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <div className='OfficeView__searchContainer'>
                <SearchableOptions
                    loading={isSearchingContacts}
                    search={search}
                    searchFunction={searchFunction}
                    cancelSearchFunction={cancelFunction}
                    searchPlaceholder={'Search by name or number'}
                    onChangeSearch={onChangeSearch}
                    isSearchResultsVisible={isSearchResultsVisible}
                    renderSearchResults={(
                        <OfficeViewSearchResults onPersonSelect={onPersonSelect} />
                    )}
                    isPaginationVisible={true}
                    paginationProps={{
                        boundaryRange: 0,
                        activePage: page,
                        ellipsisItem: null,
                        siblingRange: 2,
                        totalPages: Math.ceil(contacts_searched_count / LIMIT),
                        onPageChange: async (_, { activePage }) => {
                            await dispatch(contactsActions.contactSearch({ page: activePage, search, limit: LIMIT }));
                            setIsSearchResultsVisible(true);
                            setPage(activePage);
                        }
                    }}
                    floatingResults
                />
            </div>
            <div className='OfficeView__midContainer'>
                {contact_lookup && (
                    <>
                        <OfficeViewMiddleHeader person={contact_lookup} />
                        <OfficeViewMiddleNumbers person={contact_lookup} onNumberClick={onNumberClick} />
                    </>
                )}
            </div>
            <OfficeViewAdditionalLines />
        </div>

    )
}

export default OfficeViewPhoneMiddle;