import React from 'react';
import { Icon } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import './index.scss';
import PHONE_ICON from 'dyl-components/utils/DateTimeUtils/PHONE_ICON';

const PhoneNumber = ({ phone, onNumberClick }) => (
    <div className='OfficeView__numberContainer' onClick={() => onNumberClick(phone.phone)}>
        <Icon className={`${PHONE_ICON[phone.phone_type]} OfficeView__numberIcon`}  />
        <span className='OfficeView__number'>{PhoneUtil.formatPhoneNumber(phone.phone)}</span>
        <Icon className={`fa-solid fa-star OfficeView__numberStar ${phone.main ? "OfficeView__numberStar--active" : ""}`} />
    </div>
)

const OfficeViewMiddleNumbers = ({ person, onNumberClick }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: "100%",
            marginTop: 20,
            paddingTop: 10,
            overflow: 'auto'
        }}>
            {person.contact_id ? (
                person.contact_details?.phone?.map((phone) => (<PhoneNumber phone={phone} onNumberClick={onNumberClick} />))
            ) : (
                person.account_details?.phone?.map((phone) => (<PhoneNumber phone={phone} onNumberClick={onNumberClick} />))
            )}
        </div>
    )
}

export default OfficeViewMiddleNumbers;